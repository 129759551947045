import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Button, ThemeProvider } from '@material-ui/core';
import Home from './Home';
import { useShopify } from '../hooks';
import { useCollection } from '../context/collection';
import createTheme from '../theme/material-ui';
import NotFound from './NotFound';
import ProductLanding from './ProductLanding';
import Shirts from './Shirts';
import Hoodies from './Hoodies';
import Accessories from './Accessories';
import DarkModeToggle from "react-dark-mode-toggle";
import ProductOptionsPage from './ProductOptionsPage';

const App = () => {
  const { createShop, createCheckout, fetchCollection } = useShopify();
  const { shopifyID, accentColor, accentColorLuminance } = useCollection();

  const [themeMode, setThemeMode] = useState('dark');
  const [darkMode, setDarkMode] = useState(true);

  const switchTheme = () => {
    const newTheme = themeMode === 'light' ? 'dark' : 'light';
    setDarkMode(darkMode => !darkMode);
    setThemeMode(newTheme);
    
  }


  const theme = useMemo(() => createTheme(accentColor, accentColorLuminance), [
    accentColor,
    accentColorLuminance
  ]);

  useEffect(() => {
    createShop();
    createCheckout();
    fetchCollection(shopifyID);
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }, [shopifyID]);


  return (
    <ThemeProvider theme={theme}>
      <div id="App" data-theme={themeMode}>
        <Router>
          <div className='dark-mode-toggle'>
            <DarkModeToggle
              onChange={switchTheme}
              checked={darkMode}
              size={80}
            />
          </div>
          <Switch>
            {/* Send themeMode prop though to home component */}
            <Route exact path="/" render={(props) => <Home {...props} mode={themeMode} />} />
            <Route exact path="/products" component={Home} />
            <Route
              exact
              path="/products/:id"
              key={window.location.pathname}
              component={ProductOptionsPage}
            />
            <Route exact path="/latest" component={Home} />
            <Route exact path="/shirts" component={Shirts} />
            <Route exact path="/hoodies" component={Hoodies} />
            <Route exact path="/accessories" component={Accessories} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
};



export default App;